<template>
  <card-container :useLoading="!orderDetail">
    <template v-slot:content class="card-content" v-if="orderDetail">
      <div class="order-title">
        Orden {{ orderDetail.order_number }}
      </div>
      <div class="creation-date">
        {{ dateFormat(orderDetail.creation_date, 'D MMM YYYY') }}
      </div>
      <div class="section-container app-border-top">
        <v-timeline class="delivery-timeline" dense clipped>
          <v-timeline-item :color="deliveryHasFinalStatus ? 'success' : 'blue'" small v-for="delivery in getDeliveryByStatus" :key="'delivery_'+delivery.status">
            <v-row justify="space-between">
              <v-col cols="12">
                <div :class="getTitleClass(delivery.status)" class="status-title">{{ getDeliveryStatusName(delivery.status) }}</div>
                <div class="delivery-timeline-description" v-for="(detail, index) in delivery.details" :key="'delivery_detail_'+index">
                  {{ dateFormat(detail.creation_date, 'D [de] MMM hh:mm a') }}, {{ getDeliveryStatusDescription(detail.description, delivery.status) }}
                  <v-icon class="edit-icon" dense @click="openModal({ delivery_record_id: detail.delivery_record_id, status: delivery.status, description: detail.description})">
                    mdi-pencil
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item hide-dot>
            <v-btn color="blue-grey" class="white--text text-none" @click="openModal">
              <v-icon left dark>
                mdi-plus-box
              </v-icon>
              Agregar
            </v-btn>
          </v-timeline-item>
        </v-timeline>
      </div>
      <div class="section-container app-border-top">
        <v-list-item class="delivery-list-item" flat>
          <v-list-item-avatar>
            <v-icon class="blue" dark>
              mdi-truck
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content three-line>
            <v-list-item-title class="location-title font-weight-bold">
              Tipo de entrega
            </v-list-item-title>
            <v-list-item-title class="location-title">{{ getDeliveryTypeDescription(orderDetail.delivery.delivery_type) }}</v-list-item-title>
            <v-list-item-subtitle class="location-description">
              <div :class="'status-description-' + getDeliveryLastRecord.status" v-if="deliveryHasFinalStatus">
                {{ getDeliveryStatusTitle(getDeliveryLastRecord.status) }} el {{ dateFormat(getDeliveryLastRecord.creation_date, 'D MMM YYYY') }}
              </div>
              <div v-else>
                <span v-if="toBePickedUpInStore">
                  Retira a partir del {{ dateFormat(addDaysToCurrentDate(orderDetail.delivery.delivery_days), 'D MMM YYYY') }}
                </span>
                <span v-else>
                  Llega el {{ dateFormat(addDaysToCurrentDate(orderDetail.delivery.delivery_days), 'D MMM YYYY') }}
                </span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div class="section-container app-border-top">
        <v-list-item class="delivery-list-item" flat>
          <v-list-item-avatar>
            <v-icon class="blue" dark>
              mdi-map-marker
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content three-line>
            <v-list-item-title class="location-title">
              <span class="location-receiver">Recibe:</span>
              <span>&nbsp;{{ orderDetail.user.first_name }} {{ orderDetail.user.last_name }}</span>
            </v-list-item-title>
            <v-list-item-title class="location-title">{{ orderDetail.address.address }}</v-list-item-title>
            <v-list-item-subtitle class="location-description" v-if="orderDetail.address.additional_address">
              {{ orderDetail.address.additional_address }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="location-description">
              {{ orderDetail.address.neighborhood }}, {{ orderDetail.address.city.name }}, {{ orderDetail.address.region.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>

      <v-dialog v-model="modal" persistent fullscreen>
        <v-card>
          <v-toolbar dark :color="'var(--app-button-bg-color)'">
            <v-btn icon dark @click="closeModal">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <span class="modal-title" v-if="isRecordUpdate">Actualizar registro de envío</span>
              <span class="modal-title" v-else>Agregar registro de envío</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center" class="modal-content" no-gutters>
              <v-col xl="5" lg="7" md="8" sm="10" cols="12">
                <v-row justify="center" no-gutters>
                  <v-col cols="12">
                    <div class="modal-description">
                      <span v-if="isRecordUpdate">Aquí puedes actualizar los registros de envíos que no se crearon apropiadamente.</span>
                      <span v-else>Aquí puedes agregar nuevos registros de envío, para mantener informado a tus clientes sobre el estado de sus entregas.</span>
                    </div>
                    <v-form class="form-container" ref="form">
                      <delivery-record-form :deliveryRecord="deliveryRecord" />
                    </v-form>
                  </v-col>
                  <v-col cols="12">
                    <v-row justify="end" no-gutters>
                      <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                        <v-btn class="text-none btn-default" depressed large @click="saveDeliveryRecord" :loading="loading">
                          <span v-if="isRecordUpdate">Actualizar</span>
                          <span v-else>Guardar</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </card-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CardContainer from '@/components/Card/CardContainer'
import DeliveryRecordForm from '@/components/Delivery/DeliveryRecordForm'
import { getDeliveryStatusName, getDeliveryStatusDescription, getDeliveryStatusTitle, DELIVERY_STATUS_PENDING, DELIVERY_STATUS_IN_PROGRESS, DELIVERY_STATUS_DELIVERED, DELIVERY_STATUS_RETURNED } from '@/shared/delivery'
import { getDeliveryTypeDescription, DELIVERY_TYPE_COLLECT_IN_STORE } from '@/shared/deliveryTypes'
import { dateFormat } from '@/shared/format'
import { cloneObject } from '@/shared/clone'
import { addDaysToCurrentDate } from '@/shared/date'

export default {
  components: {
    CardContainer,
    DeliveryRecordForm
  },
  computed: {
    ...mapState('shared', ['error']),
    ...mapState('shared', ['loading']),
    ...mapState('order', ['orderDetail']),
    ...mapGetters('order', ['getDeliveryTitle', 'getDeliveryByStatus', 'getDeliveryLastStatus', 'getDeliveryLastRecord']),
    isRecordUpdate () {
      return !!this.deliveryRecord && !!this.deliveryRecord.delivery_record_id
    },
    deliveryHasFinalStatus () {
      const record = this.getDeliveryLastRecord

      return record && (record.status === DELIVERY_STATUS_DELIVERED || record.status === DELIVERY_STATUS_RETURNED)
    },
    toBePickedUpInStore () {
      return this.orderDetail.delivery.delivery_type === DELIVERY_TYPE_COLLECT_IN_STORE
    }
  },
  data () {
    return {
      getDeliveryStatusName,
      getDeliveryStatusDescription,
      dateFormat,
      getDeliveryTypeDescription,
      getDeliveryStatusTitle,
      addDaysToCurrentDate,
      modal: false,
      deliveryRecord: {}
    }
  },
  methods: {
    ...mapActions('order', ['getOrder', 'createDeliveryRecord', 'updateDeliveryRecord']),
    getTitleClass (status) {
      if (status === DELIVERY_STATUS_PENDING || status === DELIVERY_STATUS_IN_PROGRESS) {
        return this.getDeliveryLastStatus === status ? 'current-status-title' : 'status-title'
      }

      return status === DELIVERY_STATUS_DELIVERED ? 'status-title-delivered' : 'status-title-returned'
    },
    openModal (record = undefined) {
      if (record) {
        this.deliveryRecord = cloneObject(record)
      }

      this.modal = true
    },
    closeModal () {
      this.deliveryRecord = {}
      this.modal = false
    },
    async saveDeliveryRecord () {
      if (!this.$refs.form.validate()) {
        return
      }

      await this.saveOrderDelivery()
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      this.deliveryRecord = cloneObject({})
      this.modal = false
      this.getOrder(this.orderDetail.order_id)
    },
    async saveOrderDelivery () {
      const data = { orderID: this.orderDetail.order_id, deliveryRecord: this.deliveryRecord }

      if (this.deliveryRecord.delivery_record_id) {
        await this.updateDeliveryRecord(data)
        return
      }

      await this.createDeliveryRecord(data)
    }
  },
  mounted () {
    this.getOrder(this.$route.params.id)
  }
}
</script>

<style scoped>
.card-content {
  height: 100%;
}
.order-title {
  font-size: 1.125rem;
  font-weight: bold;
}
.section-container {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}
.delivery-timeline {
  margin-left: -1.75rem;
  margin-right: 1rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.delivery-timeline-description {
  opacity: 0.6;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: .0071428571em;
}
.creation-date {
  font-size: 0.875rem;
  padding-bottom: 0.75rem;
  color: #7D7D7DFF;
}
.status-title {
  color: rgba(0, 0, 0, 0.25);
  font-size: 1rem;
}
.current-status-title {
  color: #000000;
  font-size: 1rem;
}
.status-title-delivered {
  color: var(--app-color-delivery-delivered);
  font-size: 1rem;
  font-weight: 600;
}
.status-title-returned {
  color: var(--app-color-delivery-returned);
  font-size: 1rem;
  font-weight: 600;
}
.status-description-delivered {
  color: var(--app-color-delivery-delivered);
  font-weight: 600;
}
.status-description-returned {
  color: var(--app-color-delivery-returned);
  font-weight: 600;
}
.delivery-list-item {
  padding: 0;
}
.edit-icon {
  color: var(--app-icon-active-color) !important;
}
.location-receiver {
  font-weight: 700;
}
.location-title {
  opacity: 0.8;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0071428571em;
}
.location-description {
  opacity: 0.9;
  font-size: 0.875rem;
  letter-spacing: 0.0071428571em;
}
.modal-title {
 text-transform: lowercase;
}
.modal-title::first-letter {
 text-transform: uppercase;
}
.modal-description {
  text-align: left;
  text-transform: lowercase;
  font-size: 0.938rem;
  margin-bottom: 1.5rem;
}
.modal-description::first-letter {
 text-transform: uppercase;
}
.modal-content {
  padding-top: 2.25rem;
}
.modal-form {
  padding-top: 1rem;
}
.modal-button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.btn-default {
  width: 100%;
}
</style>
