<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-select
        class="input-margin-right"
        v-model="deliveryRecord.status"
        :items="deliveryStatuses"
        item-text="name"
        item-value="key"
        label="Estado del envío"
        :rules="[ isRequired ]"
        outlined
        dense
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        class="input-margin-left"
        v-model="deliveryRecord.description"
        label="Descripción"
        outlined
        dense
      />
    </v-col>
  </v-row>
</template>
<script>
import { isRequired } from '@/shared/validations'
import { DELIVERY_STATUSES } from '@/shared/delivery'

export default {
  props: {
    deliveryRecord: {
      required: true
    }
  },
  data () {
    return {
      deliveryStatuses: DELIVERY_STATUSES
    }
  },
  methods: {
    isRequired
  }
}
</script>
<style scoped>
</style>
